<template>
  <v-row justify="center" class="mb-2" no-gutters>
    <v-alert 
      dense 
      outlined      
    >
      <v-card flat >
        <v-icon color="red">mdi-alert-outline</v-icon>
        <span class="ml-2">Toute modification sur la base de données peut avoir des
        conséquences sur les applications du groupe.</span>
      </v-card>      
    </v-alert>
  </v-row>
</template>

<script>
export default {
  name: "WarningEditComponent",
  mixins:[],
  props: {},
  data() {
    return {};
  },
  methods: {},
  computed: {},
  mounted() {},
}
</script>