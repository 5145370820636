var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-row",
    { staticClass: "mb-2", attrs: { justify: "center", "no-gutters": "" } },
    [
      _c(
        "v-alert",
        { attrs: { dense: "", outlined: "" } },
        [
          _c(
            "v-card",
            { attrs: { flat: "" } },
            [
              _c("v-icon", { attrs: { color: "red" } }, [
                _vm._v("mdi-alert-outline"),
              ]),
              _c("span", { staticClass: "ml-2" }, [
                _vm._v(
                  "Toute modification sur la base de données peut avoir des conséquences sur les applications du groupe."
                ),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }